import { Utils } from '../JS/Utils'
import {
    BarChartOutlined,
    BookOutlined,
    FlagOutlined,
    MailOutlined,
    MoneyCollectOutlined,
    PieChartOutlined,
    SearchOutlined,
    TransactionOutlined,
    UserOutlined,
    WechatOutlined,
    QrcodeOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCog,
    faUserCog,
    faBullhorn,
    faVideo,
    faUser,
    faColumns,
    faSmileBeam,
    faStar,
    faQuestion,
    faEnvelope,
    faBlog,
    faHashtag,
    faPager,
    faComments,
    faFileInvoice,
    faBan,
} from '@fortawesome/free-solid-svg-icons'

export const sideBarData = () => {
    return [
        {
            show: Utils.isUserReadOnly(),
            title: 'Users',
            key: 'users',
            Icon: <UserOutlined />,
            subMenu: [
                {
                    title: 'search',
                    key: 'searchuser',
                    Icon: <SearchOutlined />,
                    route: '/searchuser/',
                    show: true,
                },
                {
                    title: 'Notifications',
                    key: 'notification',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faBullhorn}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/notifications',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Award iCash',
                    key: 'awardicash',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faStar}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/awardicash',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Drip',
                    key: 'drip',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/drip',
                    show: Utils.isUserMarketingMember(),
                },
                {
                    title: 'add-courses-by-purchase',
                    key: 'Add Courses',
                    Icon: <BookOutlined />,
                    route: '/add-courses-by-purchase',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'DND',
                    key: 'dnd',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faBan}
                            style={{
                                marginRight: 10,
                            }}
                        />
                    ),
                    route: '/dnd',
                    show: Utils.userIsSalesMember(),
                },
            ],
        },
        {
            show:
                Utils.userHasTransactionsAccess() ||
                Utils.userHasAddToManualTransactionQueueAccess() ||
                Utils.userIsSalesMember() ||
                Utils.isUserSiteManager(),
            title: 'Ecommerce',
            key: 'ecommerce',
            Icon: <MoneyCollectOutlined />,
            subMenu: [
                {
                    title: 'Transactions',
                    key: 'transactions',
                    Icon: <TransactionOutlined />,
                    route: '/transactions/transactions',
                    show: Utils.userHasTransactionsAccess(),
                },
                {
                    title: 'Coupons',
                    key: '11',
                    Icon: <PieChartOutlined />,
                    route: '/Coupons',
                    show:
                        Utils.userIsSalesMember() || Utils.isUserSiteManager(),
                },
                {
                    title: 'Queue',
                    key: 'transactionqueue',
                    Icon: <TransactionOutlined />,
                    route: '/transactionqueue',
                    show: Utils.userHasAddToManualTransactionQueueAccess(),
                },
                {
                    title: 'Partners',
                    key: 'partner',
                    Icon: <PieChartOutlined />,
                    route: '/partners',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Partner Invoices',
                    key: 'partnerinvoices',
                    Icon: <PieChartOutlined />,
                    route: '/partnerinvoices',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Drop Coupons',
                    key: 'cartdropcoupons',
                    Icon: <PieChartOutlined />,
                    route: '/cartdropcoupons',
                    show:
                        Utils.userIsSalesMember() || Utils.isUserSiteManager(),
                },
                {
                    title: 'Update Transactions',
                    key: 'update-transactions',
                    Icon: <PieChartOutlined />,
                    route: '/update-transactions',
                    show: Utils.isUserAdmin(),
                },
            ],
        },
        {
            show:
                !Utils.isUserExternalSalesMember() &&
                !Utils.isSeoExternalTeam(),
            title: 'Sales',
            key: 'carts',
            Icon: <TransactionOutlined />,
            subMenu: [
                {
                    title: 'Leads',
                    key: 'leads',
                    Icon: <TransactionOutlined />,
                    route: '/leads/internal',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Stream Split Board',
                    key: 'leads-stream-wise-dashboard',
                    Icon: <BarChartOutlined />,
                    route: '/leads-stream-wise-dashboard',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Calls Report',
                    key: 'sales-team-calls-report',
                    Icon: <BarChartOutlined />,
                    route: '/sales-team-calls-report',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Calls - Lead Type Report',
                    key: 'leads-dashboard-steam-leadtype',
                    Icon: <BarChartOutlined />,
                    route: '/leads-dashboard-steam-leadtype',
                    show: true,
                },
                {
                    title: 'Leads Current Status',
                    key: 'leads-current-status',
                    Icon: <BarChartOutlined />,
                    route: '/leads-current-status',
                    show: true,
                },
                {
                    title: 'Sales Report',
                    key: 'salesreport',
                    Icon: <BarChartOutlined />,
                    route: '/salesreport',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Dashboard',
                    key: 'cartdashboard',
                    Icon: <BarChartOutlined />,
                    route: '/leadsdashboard',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Attended New Leads Stats',
                    key: 'unattended-leads-stats',
                    Icon: <BarChartOutlined />,
                    route: '/unattended-leads-stats',
                    show: !Utils.isUserMarketingAgency(),
                },
                {
                    title: 'Lead Types',
                    key: 'cartleadtypes',
                    Icon: <PieChartOutlined />,
                    route: '/cartleadtypes',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'External Team',
                    key: 'external-sales-team',
                    Icon: <PieChartOutlined />,
                    route: '/external-sales-team',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Webinar',
                    key: 'webinar',
                    Icon: <PieChartOutlined />,
                    route: '/webinar',
                    show: true,
                },
            ],
        },
        {
            show: Utils.userHasAnalyticsRevenueRole(),
            title: 'Stats - Revenue',
            key: 'statsrevenue',
            Icon: <BarChartOutlined />,
            subMenu: [
                {
                    title: 'DSR',
                    key: 'dsr',
                    Icon: <BarChartOutlined />,
                    route: '/dsr',
                    show: true,
                },
                {
                    title: 'Revenue',
                    key: 'stats2',
                    Icon: <BarChartOutlined />,
                    route: '/revenuestats/',
                    show: true,
                },
                {
                    title: 'ARPU',
                    key: 'arpu',
                    Icon: <BarChartOutlined />,
                    route: '/arpu',
                    show: true,
                },
                {
                    title: 'Revenue Split',
                    key: 'revenuesplit',
                    Icon: <BarChartOutlined />,
                    route: '/revenuesplit',
                    show: true,
                },
                {
                    title: 'Subject Revenue',
                    key: 'subjectrevenue',
                    Icon: <BarChartOutlined />,
                    route: '/subjectrevenue',
                    show: true,
                },
                {
                    title: 'State Wise Split',
                    key: 'revenuesplitbystate',
                    Icon: <BarChartOutlined />,
                    route: '/revenuesplitbystate',
                    show: true,
                },
                {
                    title: 'Purchase Stats',
                    key: 'purchase-stats',
                    Icon: <BarChartOutlined />,
                    route: '/stats/purchase-stats',
                    show: true,
                },
                {
                    title: 'First Purchase Stats',
                    key: 'first-purchase-stats',
                    Icon: <BarChartOutlined />,
                    route: '/stats/first-purchase-stats',
                    show: true,
                },
                {
                    title: 'Aging Stats',
                    key: 'purchase-aging-stats',
                    Icon: <BarChartOutlined />,
                    route: '/stats/purchase-aging-stats',
                    show: true,
                },
                {
                    title: 'Attempt Stats',
                    key: 'attempt-stats',
                    Icon: <BarChartOutlined />,
                    route: '/stats/attempt-stats',
                    show: true,
                },
            ],
        },
        {
            show: Utils.userHasAnalyticsUsersRole(),
            title: 'Stats - Users',
            key: 'statsusers',
            Icon: <BarChartOutlined />,
            subMenu: [
                {
                    title: 'Users',
                    key: 'stats1',
                    Icon: <BarChartOutlined />,
                    route: '/userstats/',
                    show: true,
                },
                {
                    title: 'Stream Split - Week Wise',
                    key: 'user-stream-split-week-wise',
                    Icon: <BarChartOutlined />,
                    route: '/stats/user-stream-split-week-wise',
                    show: true,
                },
                {
                    title: 'Stream Split',
                    key: 'userstreamsplitstats',
                    Icon: <BarChartOutlined />,
                    route: '/userstreamsplitstats/',
                    show: true,
                },
                {
                    title: 'Subscribers',
                    key: 'activesubscribers',
                    Icon: <BarChartOutlined />,
                    route: '/activesubscribers',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Mins Watched',
                    key: 'totalwatchedstats',
                    Icon: <BarChartOutlined />,
                    route: '/totalwatchedstats',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Video Requests',
                    key: 'stats3',
                    Icon: <BarChartOutlined />,
                    route: '/videorequeststats/',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Active Users',
                    key: 'activeusers',
                    Icon: <BarChartOutlined />,
                    route: '/activeuserstats/',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Course Completion',
                    key: 'course-completion-by-attempt',
                    Icon: <BarChartOutlined />,
                    route: '/course-completion-by-attempt',
                    show: Utils.isUserSiteManager(),
                },
            ],
        },
        {
            show: Utils.isUserAdmin(),
            title: 'Stats - Faculty',
            key: 'statsfaculty',
            Icon: <BarChartOutlined />,
            subMenu: [
                {
                    title: 'Faculty Wise',
                    key: 'facultywisestats',
                    Icon: <BarChartOutlined />,
                    route: '/facultywisestats/',
                    show: true,
                },
                {
                    title: 'All Faculty',
                    key: 'allfacultyrevenuestats',
                    Icon: <BarChartOutlined />,
                    route: '/allfacultyrevenuestats/',
                    show: true,
                },
                {
                    title: 'Commission Report',
                    key: 'faculty-commission-report',
                    Icon: <BarChartOutlined />,
                    route: '/faculty-commission-report',
                    show: true,
                },
                {
                    title: 'Course Wise Students',
                    key: 'course-wise-students',
                    Icon: <BarChartOutlined />,
                    route: '/course-wise-students',
                    show: true,
                },
            ],
        },
        {
            show: Utils.userHasForumsAccess(),
            title: 'Forums',
            key: 'forums',
            Icon: <WechatOutlined />,
            subMenu: [
                {
                    title: 'Forums',
                    key: 'forums1',
                    Icon: <WechatOutlined />,
                    route: '/forum/',
                    show: true,
                },
                {
                    title: 'Groups',
                    key: 'forumcategory',
                    Icon: <WechatOutlined />,
                    route: '/forumcategory',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'User Mapping',
                    key: 'forum-group-user-mapping',
                    Icon: <WechatOutlined />,
                    route: '/forum-group-user-mapping',
                    show: Utils.isUserSiteManager(),
                },
            ],
        },
        {
            show: Utils.userHasReportsAccess() || Utils.isUserSiteManager(),
            title: 'Reports',
            key: 'reports',
            Icon: <BarChartOutlined />,
            subMenu: [
                {
                    title: 'Transactions',
                    key: 'transactionsreport',
                    Icon: <BarChartOutlined />,
                    route: '/report/transactions',
                    show: true,
                },
                {
                    title: 'Transaction Items',
                    key: 'transactionitemsreport',
                    Icon: <BarChartOutlined />,
                    route: '/report/transaction-items',
                    show: true,
                },
                {
                    title: 'Users',
                    key: 'usersreport',
                    Icon: <BarChartOutlined />,
                    route: '/report/users',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Subject Revenue',
                    key: 'subjectwiserevenuereport',
                    Icon: <BarChartOutlined />,
                    route: '/report/subjectwiserevenuereport',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Faculty Revenue',
                    key: 'facultyrevenuereport',
                    Icon: <BarChartOutlined />,
                    route: '/report/facultyrevenuereport',
                    show: Utils.isUserAdmin(),
                },
                {
                    title: 'Course Enrollments',
                    key: 'course-enrollment-report',
                    Icon: <BarChartOutlined />,
                    route: '/report/course-enrollment-report',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Course Completion',
                    key: 'subjectcompletionreport',
                    Icon: <BarChartOutlined />,
                    route: '/report/subject-completion-report',
                    show: Utils.isUserSiteManager(),
                },
                {
                    title: 'Leads',
                    key: 'leads',
                    Icon: <BarChartOutlined />,
                    route: '/report/leads',
                    show: true,
                },
            ],
        },
        {
            show:
                Utils.userHasCourseReadOnlyAccess() ||
                Utils.userHasCourseReviewsRole() ||
                Utils.isUserMarketingMember() ||
                Utils.userIsSalesMember(),
            title: 'Courses',
            key: 'sub3',
            Icon: <BookOutlined />,
            subMenu: [
                {
                    show: Utils.userHasCourseReviewsRole(),
                    title: 'Reviews',
                    key: 'coursereviews',
                    Icon: <PieChartOutlined />,
                    route: '/coursereviews',
                },
                {
                    title: 'Courses',
                    key: '8',
                    Icon: <PieChartOutlined />,
                    route: '/courses',
                    show: true,
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Books',
                    key: 'books-list',
                    Icon: <BookOutlined />,
                    route: '/books',
                },
                {
                    show: true,
                    title: 'Catalog',
                    key: 'coursecatalog',
                    Icon: <PieChartOutlined />,
                    route: '/coursecatalog',
                },
                {
                    show:
                        Utils.userHasCourseReadOnlyAccess() ||
                        Utils.isUserAdmin(),
                    title: 'Extension',
                    key: 'extensionattempt',
                    Icon: <BookOutlined />,
                    route: '/extension/attempt',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Faculty',
                    key: 'facultysubmenu',
                    Icon: <UserOutlined />,
                    subMenu: [
                        {
                            title: 'Faculty',
                            key: 'faculty',
                            Icon: <UserOutlined />,
                            route: '/faculty',
                            show: true,
                        },
                        {
                            title: 'Contracts',
                            key: 'facultycontract',
                            Icon: <BookOutlined />,
                            route: '/facultycontract',
                            show: true,
                        },
                        {
                            title: 'Sales',
                            key: 'facultysalessummary',
                            Icon: <MoneyCollectOutlined />,
                            route: '/facultysalessummary',
                            show: true,
                        },
                        {
                            title: 'Invoice',
                            key: 'facultyinvoice',
                            Icon: (
                                <FontAwesomeIcon
                                    icon={faFileInvoice}
                                    style={{ marginRight: 10 }}
                                />
                            ),
                            route: '/facultyinvoice',
                            show: true,
                        },
                    ],
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Misc',
                    key: 'coursemisc',
                    Icon: <BookOutlined />,
                    subMenu: [
                        {
                            title: 'Attempts',
                            key: '9',
                            Icon: <PieChartOutlined />,
                            route: '/attempts',
                            show: true,
                        },
                        {
                            title: 'Groups',
                            key: '7',
                            Icon: <PieChartOutlined />,
                            route: '/groups',
                            show: true,
                        },
                        {
                            title: 'Group Reviews',
                            key: 'group-reviews',
                            Icon: <PieChartOutlined />,
                            route: '/group-reviews',
                            style: { whiteSpace: 'normal', height: 'auto' },
                            show: true,
                        },
                        {
                            show: Utils.isUserSiteManager(),
                            title: 'Free Courses',
                            key: 'FreeCourses',
                            Icon: <PieChartOutlined />,
                            route: '/freecourses',
                        },
                    ],
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Videos',
                    key: 'Video',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faVideo}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    subMenu: [
                        {
                            title: 'Videos',
                            key: 'videos',
                            Icon: (
                                <FontAwesomeIcon
                                    icon={faVideo}
                                    style={{ marginRight: 10 }}
                                />
                            ),
                            route: '/videos',
                            show: true,
                        },
                        {
                            title: 'Vendors',
                            key: 'vendors',
                            Icon: (
                                <FontAwesomeIcon
                                    icon={faUser}
                                    style={{ marginRight: 10 }}
                                />
                            ),
                            route: '/videovendor',
                            show: true,
                        },
                    ],
                },
                {
                    show: Utils.isUserMarketingMember(),
                    title: 'Nudge',
                    key: 'nudge',
                    Icon: <BookOutlined />,
                    subMenu: [
                        {
                            title: 'Study Mins',
                            key: 'study-mins-course-per-day',
                            Icon: <PieChartOutlined />,
                            route: '/nudge/study-mins-course-per-day',
                            show: Utils.isUserAdmin(),
                        },
                        {
                            title: 'Notifications',
                            key: 'nudge-notifications',
                            Icon: <PieChartOutlined />,
                            route: '/nudge/notifications',
                            show: true,
                        },
                    ],
                },
            ],
        },
        {
            show: Utils.userHasFlagAccess(),
            title: 'Flagged',
            key: 'flagged',
            Icon: <FlagOutlined />,
            subMenu: [
                {
                    title: 'Flagged',
                    key: 'flag1',
                    Icon: <FlagOutlined />,
                    route: '/flag/',
                    show: true,
                },
            ],
        },
        {
            show: true,
            title: 'Misc',
            key: 'misc',
            Icon: <FlagOutlined />,
            subMenu: [
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Home Items',
                    key: '21',
                    Icon: <PieChartOutlined />,
                    route: '/homeitems/home',
                },
                {
                    show: true,
                    title: 'Short Links',
                    key: 'short-links',
                    Icon: <PieChartOutlined />,
                    route: '/short-links',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Pages',
                    key: '19',
                    Icon: <PieChartOutlined />,
                    route: '/page/pages',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Surveys',
                    key: 'Surveys',
                    Icon: <PieChartOutlined />,
                    route: '/surveys',
                },
                {
                    show: Utils.userHasLiveClassesManagerRole(),
                    title: 'Live Classes',
                    key: 'live-class-schedule',
                    Icon: <PieChartOutlined />,
                    route: '/live-class-schedule',
                },
                {
                    show: Utils.isUploadToS3(),
                    title: 'Upload & Get Link',
                    key: 'upload-and-get-link',
                    Icon: <PieChartOutlined />,
                    route: '/upload-and-get-link',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Email Templates',
                    key: 'email-templates',
                    Icon: <PieChartOutlined />,
                    route: '/email-templates',
                },
                {
                    title: 'Deeplinks',
                    key: 'deeplinks',
                    Icon: <PieChartOutlined />,
                    route: '/deeplinks',
                    show: true,
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Pincodes',
                    key: 'pincodes',
                    Icon: <PieChartOutlined />,
                    route: '/pincodes',
                },
                {
                    show: true,
                    title: 'QR Code',
                    key: 'qrcode',
                    Icon: <QrcodeOutlined />,
                    route: '/qr-code',
                },
            ],
        },
        {
            show: Utils.userHasResourceCatalogAccess(),
            title: 'Free Resoures',
            key: 'sub1',
            Icon: <MailOutlined />,
            subMenu: [
                {
                    title: 'Catlog',
                    key: '1',
                    Icon: <PieChartOutlined />,
                    route: '/freeresources/catlog',
                    show: true,
                },
                {
                    title: 'Files',
                    key: '3',
                    Icon: <PieChartOutlined />,
                    route: '/freeresources/files',
                    show: true,
                },
            ],
        },
        {
            show:
                Utils.userHasForumsAccess() ||
                Utils.isUserNewsManager() ||
                Utils.isUserSiteManager(),
            title: 'News',
            key: 'sub2',
            Icon: <MailOutlined />,
            subMenu: [
                {
                    show:
                        Utils.isUserNewsManager() || Utils.isUserSiteManager(),
                    title: 'News',
                    key: '4',
                    Icon: <PieChartOutlined />,
                    route: '/news',
                },
                {
                    title: 'Comments',
                    key: 'newswithopencommentslist',
                    Icon: <PieChartOutlined />,
                    route: '/newswithopencommentslist',
                    show: true,
                },
            ],
        },
        {
            show: Utils.userHasMCQAccess(),
            title: 'MCQ',
            key: 'sub6',
            Icon: <MailOutlined />,
            subMenu: [
                {
                    title: 'Search',
                    key: 'Search',
                    Icon: <SearchOutlined />,
                    route: '/mcq/search',
                    show: true,
                },
                {
                    title: 'Tests',
                    key: '18',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/tests',
                    show: true,
                },
                {
                    title: 'Course Tests',
                    key: 'course-tests',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/course-tests',
                    show: true,
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Upload Question',
                    key: '16',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/uploadquestions',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Replace Questions',
                    key: 'replacequestions',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/replacequestions',
                },
                {
                    title: 'Subjects',
                    key: '13',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/subjects',
                    show: true,
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Difficulty',
                    key: '14',
                    Icon: <PieChartOutlined />,
                    route: '/mcq/difficulty',
                },
            ],
        },
        {
            show:
                Utils.userIsStreamPageManager() ||
                Utils.isUserSiteManager() ||
                Utils.isTestimonialsManager() ||
                Utils.isDemoVideosManager(),
            title: 'Website',
            key: 'Website',
            Icon: (
                <FontAwesomeIcon icon={faColumns} style={{ marginRight: 10 }} />
            ),
            subMenu: [
                {
                    show: Utils.isTestimonialsManager(),
                    title: 'Testimonials',
                    key: 'WOF',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faSmileBeam}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/testimonials',
                },
                {
                    show: Utils.isDemoVideosManager(),
                    title: 'Demo Videos',
                    key: 'demovideos',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faVideo}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/demovideos',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'FAQ',
                    key: 'faq',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faQuestion}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/faq',
                },
                {
                    show: Utils.userIsStreamPageManager(),
                    title: 'Course Guides',
                    key: 'streampage',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faColumns}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/streampage',
                },
            ],
        },
        {
            show:
                Utils.userHasForumsAccess() ||
                Utils.userIsBlogManager() ||
                Utils.isSeoExternalTeam(),
            title: 'Blog',
            key: 'Blog',
            Icon: <FontAwesomeIcon icon={faBlog} style={{ marginRight: 10 }} />,
            subMenu: [
                {
                    show:
                        Utils.userIsBlogManager() || Utils.isSeoExternalTeam(),
                    title: 'Blog Pages',
                    key: 'blogpage',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faPager}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/blog/pages',
                },
                {
                    show: !Utils.isSeoExternalTeam(),
                    title: 'Comments',
                    key: 'blogcommentslist',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faComments}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/blog/opencommentslist',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Super Blogs',
                    key: 'blogsuper',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faStar}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/blog/super',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Categories',
                    key: 'blogcategory',
                    Icon: <BookOutlined />,
                    route: '/blog/category',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Authors',
                    key: 'blogauthor',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faUser}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/blog/author',
                },
                {
                    show: Utils.isUserSiteManager(),
                    title: 'Tags',
                    key: 'blogtag',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faHashtag}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/blog/tags',
                },
            ],
        },
        {
            show: Utils.isUserAdmin(),
            title: 'Study Planner',
            key: 'StudyPlanner',
            Icon: <BookOutlined />,
            subMenu: [
                {
                    title: 'Config',
                    key: 'study-planner-config',
                    route: '/study-planner-config',
                    show: true,
                },
                {
                    title: 'Course Config',
                    key: 'study-planner-course-config',
                    route: '/study-planner-course-config',
                    show: true,
                },
                {
                    title: 'Selection Config',
                    key: 'study-planner-course-selection-config',
                    route: '/study-planner-course-selection-config',
                    show: true,
                },
                {
                    title: 'Session Config',
                    key: 'study-planner-session-config',
                    route: '/study-planner-session-config',
                    show: true,
                },
            ],
        },
        // {
        //     show:
        //         Utils.isUserExternalSalesMember() ||
        //         Utils.isUserAdmin() ||
        //         Utils.userIsSalesMember(),
        //     title: 'Leads',
        //     key: 'leads',
        //     Icon: <TransactionOutlined />,
        //     subMenu: [
        //         {
        //             title: 'New Leads',
        //             key: 'external-sales-new-leads',
        //             Icon: <TransactionOutlined />,
        //             route: '/external-sales-new-leads',
        //             show: true,
        //         },
        //         {
        //             title: 'Old Leads',
        //             key: 'external-sales-old-leads',
        //             Icon: <TransactionOutlined />,
        //             route: '/external-sales-old-leads',
        //             show: true,
        //         },
        //         {
        //             title: 'Search Lead',
        //             key: 'external-sales-lead-search',
        //             Icon: <TransactionOutlined />,
        //             route: '/external-sales-lead-search',
        //             show: true,
        //         },
        //         {
        //             title: 'Calls Report',
        //             key: 'external-sales-calls-report',
        //             Icon: <BarChartOutlined />,
        //             route: '/external-sales-calls-report',
        //             show: true,
        //         },
        //         {
        //             title: 'Dashboard',
        //             key: 'external-sales-dashboard',
        //             Icon: <BarChartOutlined />,
        //             route: '/external-sales-dashboard',
        //             show: true,
        //         },
        //         {
        //             show:
        //                 Utils.isUserExternalSalesManager() ||
        //                 Utils.userIsSalesMember(),
        //             title: 'Unattended Stats',
        //             key: 'external-sales-unattended-leads-stats',
        //             Icon: <BarChartOutlined />,
        //             route: '/external-sales-unattended-leads-stats',
        //         },
        //     ],
        // },
        {
            show:
                Utils.isCourierReadOnly() || Utils.userHasTransactionsAccess(),
            title: 'Courier',
            key: 'Couriers',
            Icon: <BookOutlined />,
            subMenu: [
                {
                    show: Utils.isCourierReadOnly(),
                    title: 'Courier',
                    key: 'couriers',
                    Icon: <BookOutlined />,
                    route: '/courier',
                },
                {
                    show: Utils.isCourierAnalytics(),
                    title: 'Stats',
                    key: 'courier-stats',
                    Icon: <BarChartOutlined />,
                    route: '/stats/courier-stats',
                },
                {
                    show: Utils.userHasTransactionsAccess(),
                    title: 'Book Transactions',
                    key: 'book-transactions',
                    Icon: <TransactionOutlined />,
                    route: '/book-transactions',
                },
            ],
        },
        {
            show: Utils.isUserAdmin(),
            title: 'College',
            key: 'College',
            Icon: <UserOutlined />,
            subMenu: [
                {
                    show: Utils.isUserAdmin(),
                    title: 'Colleges',
                    key: 'colleges',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faUserCog}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/colleges',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Attendance',
                    key: 'attendance',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faUserCog}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/attendance',
                },
            ],
        },
        {
            show: Utils.isIlManager(),
            title: 'IL_Employee',
            key: 'IL_Employee',
            Icon: <UserOutlined />,
            subMenu: [
                {
                    show: Utils.isIlManager(),
                    title: 'Employees',
                    key: '/il/employees',
                    Icon: <UserOutlined />,
                    route: '/il/employees',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Holidays',
                    key: '/il/holidays',
                    Icon: <BookOutlined />,
                    route: '/il/holidays',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Policies',
                    key: '/il/policies',
                    Icon: <BookOutlined />,
                    route: '/il/policies',
                },
                {
                    show: Utils.isUserAdmin(),
                    title: 'Roles',
                    key: '/il/employee-roles',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faUserCog}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/il/employee-roles',
                },
            ],
        },
        {
            show: Utils.isUserAdmin(),
            title: 'Admin',
            key: 'Admin',
            Icon: <FontAwesomeIcon icon={faCog} style={{ marginRight: 10 }} />,
            subMenu: [
                {
                    show: Utils.isSuperUserAdmin(),
                    title: 'User Roles',
                    key: 'userroles',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faUserCog}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/admin/userroles',
                },
                {
                    show: true,
                    title: 'Config',
                    key: 'config',
                    Icon: (
                        <FontAwesomeIcon
                            icon={faCog}
                            style={{ marginRight: 10 }}
                        />
                    ),
                    route: '/admin/config',
                },
            ],
        },
    ]
}
